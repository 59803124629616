<mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Arquivos da empresa</span>
    </div>
</mat-toolbar>
<div class="mat-white-bg mat-elevation-z2" fxLayout="column"
     fxLayoutAlign="space-between stretch">
    <div matDialogContent>
        <div fxLayout="column" class="p-15" fxLayoutAlign="start start">
            <div>
                <mat-form-field appearance="outline" style="min-width: 200px;">
                    <mat-select placeholder="Grupo" [formControl]="aiUserGroupIdControl">
                        <mat-option *ngFor="let aiUserGroup of aiUserGroups" [value]="aiUserGroup.id">
                            {{ aiUserGroup.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-slide-toggle [formControl]="isDefaultControl" [hidden]="!isAdmin" class="ml-25">
                    Mostrar apenas arquivos das parceiras de software
                </mat-slide-toggle>
            </div>
            <div fxLayout="column" fxLayoutAlign="start center" class="width-100-percent">
                <table mat-table [dataSource]="dataSource" class="width-100-percent" style="border: 1px solid #ddd;">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                        <td mat-cell *matCellDef="let element" class="icon">
                            <img *ngIf="element.name" src="{{imageFileService.getIconOfExtension(element.name)}}"
                                 width="60" class="p-5" alt="icon">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="pl-15">Nome</div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="text-max-400" title="{{element.name}}">
                                <b *ngIf="element.id === -3">(Arquivo já existe!) - </b>
                                {{ element.name }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="size">
                        <th mat-header-cell *matHeaderCellDef>Tamanho</th>
                        <td mat-cell *matCellDef="let element" class="w-100 no-padding">
                            <div>{{ element.size | bytes }}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>status</th>
                        <td mat-cell *matCellDef="let element" class="w-100 no-padding">
                            <div>{{ element.status}}</div>
                        </td>
                    </ng-container>


                    <!-- Name Column -->
                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef>Data de criação</th>
                        <td mat-cell *matCellDef="let element" class="w-190 no-padding">
                            <div *ngIf="element.createdAt">
                                {{ element.createdAt | date: 'dd/MM/yyyy HH:mm - EEE' }}
                            </div>
                        </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" class="text-align-center no-padding w-80">

                            <button mat-mini-fab color="warn" aria-label="delete" *ngIf="element.id > 0"
                                    (click)="delete(element)">
                                <mat-icon>delete_forever</mat-icon>
                            </button>

                            <mat-spinner *ngIf="element.id === -1" [diameter]="24" color="primary"
                                         style="margin-left: 28px;"></mat-spinner>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{
                        'yellow-200-bg': !row.id,
                        'blue-200-bg': row.id === -1,
                        'green-200-bg': row.id === -2,
                        'red-200-bg': row.id === -3
                        }"
                        style="height: 60px;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [length]="totalDocs"></mat-paginator>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <mat-spinner *ngIf="uploading" [diameter]="24" color="primary" style="margin-left: 15px; margin-bottom: 7px;"></mat-spinner>
        <input type="file" #fileInput style="display: none;" accept=".txt, .jsonl, .csv, .pdf, .docx, .html"
               (change)="onAddFiles($event)"
               multiple>
        <div [hidden]="this.isDefaultControl.value || uploading">
            <button type="button" mat-raised-button color="accent" class="pl-10" [disabled]="uploading"
                    (click)="openFileInput()" *ngIf="!hasNewFiles();else hasFiles">
                <mat-icon>add</mat-icon>
                Adicionar arquivos
            </button>
            <ng-template #hasFiles>
                <button type="button" mat-raised-button color="accent" class="pl-10" (click)="sendFiles()" [disabled]="uploading" >
                    <mat-icon>cloud_upload</mat-icon>
                    Enviar todos os arquivos
                </button>
            </ng-template>
        </div>
        <button type="button" mat-raised-button color="primary" [disabled]="uploading" (click)="onCancel()">Fechar
        </button>
    </div>
</div>
